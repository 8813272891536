<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPlansExpiringListTable"
        class="position-relative"
        :items="fetchPlansExpiring"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro encontrado"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Plan -->
        <template #cell(plan)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserPlan(data.item.plan)}`"
            class="text-capitalize"
          >
            {{ data.item.plan }}
          </b-badge>
        </template>

        <!-- Column: City/UF -->
        <template #cell(cities)="data">
          <div v-if="data.item.cities" class="text-nowrap">
            <span class="align-text-top text-capitalize"
              >{{ data.item.cities[0].name }} /
              {{ data.item.cities[0].state.abbreviation }}</span>
            
          </div>
        </template>

        <!-- Column: Mobile Phone -->
        <template #cell(user)="data">
          <div class="text-nowrap">
            <span class="align-text-top" >{{
              data.item.mobile_phone | vMask('(##) #####-####')
            }}</span>
          </div>
        </template>

        <!-- Column: Plan Expire -->
        <template #cell(diff_days)="data">
          <div class="text-nowrap align-text-center">
            <b-badge
            pill
            :variant="`light-${resolveDiffDays(data.item.diff_days)}`"
          >
            {{ countDiff(data.item.diff_days) }}
          </b-badge>
          </div>
        </template>

        <!-- Column: Plan Expire -->
        <template #cell(plan_expire_at)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              getDate(data.item.plan_expire_at)
            }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="d-flex">
            <b-button
              variant="success"
              class="ml-1"
              :href="'https://wa.me/55' + data.item.mobile_phone"
              target="_blank"
            >
              <ion-icon name="logo-whatsapp"></ion-icon>
            </b-button>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import dayjs from "dayjs";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import usePlansExpiringList from "./usePlansExpiringList";
import financialStoreModule from "../financialStoreModule";

import Vue from "vue";
import { VueMaskFilter } from 'v-mask';
Vue.filter('VMask', VueMaskFilter);

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup() {
    const FINANCIAL_APP_STORE_MODULE_NAME = "app-financial";

    // Register module
    if (!store.hasModule(FINANCIAL_APP_STORE_MODULE_NAME))
      store.registerModule(
        FINANCIAL_APP_STORE_MODULE_NAME,
        financialStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCIAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(FINANCIAL_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Em Progresso", value: "in_progress" },
      { label: "Ativo", value: "active" },
      { label: "Bloqueado", value: "blocked" },
      { label: "Inativo", value: "inactive" },
      { label: "Suspenso", value: "suspend" },
    ];

    const {
      fetchPlansExpiring,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPlansExpiringListTable,
      refetchData,

      // UI
      resolveUserPlan,
      resolveDiffDays,

      // Extra Filters
      roleFilter,
      stepFilter,
      statusFilter,
    } = usePlansExpiringList();

    return {
      fetchPlansExpiring,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPlansExpiringListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserPlan,
      resolveDiffDays,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      stepFilter,
      statusFilter,
    };
  },
  data() {
    return {
      stepRegistrationDropdown: null,
    };
  },
  mounted() {},
  methods: {
    getDate(date) {
      return dayjs(date).format("DD/MM/YYYY"); // display
    },
    countDiff(diff) {
        return diff <= 0 ? 0 : diff;
    }
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
